import React from "react"
import { SiShopify } from "react-icons/si"
import { BiConversation } from "react-icons/bi"
import { BsFileEarmarkCode } from "react-icons/bs"
const shopifyBuildingList = [
  {
    icon: <SiShopify />,
    title: "認定パートナー",
    description:
      "Shopifyの日本法人ShopifyJapanによるBC修了認定済のShopifyパートナーによる構築です。 \nスタッフによる運営のための使用方法指導(コーチング)なども行います。",
  },
  {
    icon: <BsFileEarmarkCode />,
    title: "テーマカスタマイズ",
    description:
      "Liquidコードのカスタマイズで、デフォルトテーマだけでは再現できない、あなたのお店オリジナルなストアを構築します。",
  },
  {
    icon: <BiConversation />,
    title: "コンサルティング",
    description:
      "ストアフロントの構築だけでなく、Shopifyの運用の面・オンライン向け商品開発・長期的なな商品戦略等のご相談引受いたします。(米国経営修士MBA保持)",
  },
]

export default shopifyBuildingList
