import React from "react"
import { Link } from "gatsby"

import {
  AiOutlineComment,
  AiOutlineApartment,
  AiOutlineForm,
  AiOutlineAppstoreAdd,
  AiOutlineCheckSquare,
  AiOutlineShop,
} from "react-icons/ai"
import bgImage from "../../assets/images/Onlinestore.jpg"
import { Parallax } from "react-parallax"
import Services from "../../components/Services"
import shopifyBuildingList from "../../constants/shopify-building-list"
import Seo from "../../components/utilities/Seo"
const BuildingShopify = () => {
  return (
    <section>
      <Seo
        title="Shopify構築"
        description="Shopify
              JapanBC認定パートナーがあなたのお店のオンラインストア作りを支援します。"
      />
      <Parallax
        bgImage={bgImage}
        bgImageAlt="shopify"
        strength={200}
        blur={{ min: 0, max: 1 }}
      >
        <div className="parallax-box">
          <div className="parallax-info">
            <h1>オンラインストア構築</h1>
            <h2>Shopifyパートナーによるストア構築</h2>
            <p>
              Shopify
              JapanBC認定パートナーがあなたのお店のオンラインストア作りを支援します。
              <br />
              最短で2週間ほどでオンラインストアをオープンすることができます。
              <br />
              <span>(写真素材・ロゴ文章などがすでにお揃いの場合)</span>
            </p>
            <div>
              <Link to="/onlinestore/about-shopify" className="btn-primary">
                Shopifyについて{" "}
              </Link>
            </div>
          </div>
        </div>
      </Parallax>

      <Services servicesList={shopifyBuildingList} />

      <section className="open-store">
        <h1>ストアオープンまでの流れ</h1>
        <div className="steps">
          <div className="step">
            <span>
              <AiOutlineComment /> ヒアリング
            </span>
            <p>
              まずお客様のビジネスの内容・状況・商品のこと。そして、ご希望の要件などをお伺いします。ブランドイメージ・顧客ターゲット分析・ゴール設定など。
            </p>
            <p>
              *すでにShopifyストアをお持ちでストアフロントデザイン修正などもお伺いできます。
            </p>
          </div>

          <div className="step">
            <span>
              <AiOutlineForm /> 要件定義・ご提案
            </span>
            <p>
              ヒアリング内容に沿って要件を整理し、お客様の希望にあうご提案をいたします。
            </p>
          </div>
          <div className="step">
            <span>
              <AiOutlineApartment /> ストア設計
            </span>
            <p>
              必要要件と提案内容をすり合わせオンラインストアの設計を行います。
            </p>
            <p>
              どのようなページが必要か、どのように商品やサービスを見せていくかなどをすり合わせ、大まかなデザイン・仕様などを決めていきます。
            </p>
          </div>
          <div className="step">
            <span>
              <AiOutlineAppstoreAdd /> サイト構築
            </span>
            <p>
              サイトの構築に取り掛かります。お客様のデザイン希望に応じて、ストアテーマ(ストアの見た目)をカスタマイズします。必要に応じて写真撮影・イラストデザインなどを用意します。
            </p>
          </div>
          <div className="step">
            <span>
              <AiOutlineCheckSquare /> テスト・修正
            </span>
            <p>
              Shopifyについてコーチングを行いながら、テストとストアデザイン修正を行います。コーチングを行いながら修正を行うので、初めてのオンラインストア構築でも、できる限り希望に沿ったオンラインストア構築ができるようサポートいたします。
            </p>
          </div>
          <div className="step">
            <span>
              <AiOutlineShop /> オープン・運営・保守
            </span>
            <p>
              オンラインストアをオープンさせます。お客様のオンラインストアの運営・保守などをShopifyパートナーとしてサポートいたします。
            </p>
          </div>
        </div>
      </section>
    </section>
  )
}

export default BuildingShopify
